import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const PannableImage = ({ children, className, onClick, hide }) => {
  const containerRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [startY, setStartY] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)
  const [moved, setMoved] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(1)

  // Ensure the container is focusable for keyboard events
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.setAttribute('tabindex', '0')
    }
  }, [])

  const handleMouseDown = e => {
    setMoved(false)
    setIsDragging(true)
    containerRef.current.style.cursor = 'grabbing'
    setStartX(e.pageX - containerRef.current.offsetLeft)
    setStartY(e.pageY - containerRef.current.offsetTop)
    setScrollLeft(containerRef.current.scrollLeft)
    setScrollTop(containerRef.current.scrollTop)
  }

  const handleMouseLeave = () => {
    setIsDragging(false)
    containerRef.current.style.cursor = 'grab'
  }

  const handleMouseUp = () => {
    setIsDragging(false)
    containerRef.current.style.cursor = 'grab'
  }

  const handleMouseMove = e => {
    if (!isDragging) return
    e.preventDefault()
    const x = e.pageX - containerRef.current.offsetLeft
    const y = e.pageY - containerRef.current.offsetTop
    const walkX = (x - startX) * 1 // Adjust the multiplier for faster/slower panning
    const walkY = (y - startY) * 1 // Adjust the multiplier for faster/slower panning
    if (Math.abs(walkX) + Math.abs(walkY) > 40) setMoved(true)
    containerRef.current.scrollLeft = scrollLeft - walkX
    containerRef.current.scrollTop = scrollTop - walkY
  }

  const handleClick = () => {
    if (!moved && onClick) {
      onClick()
    }
  }

  const handleKeyDown = e => {
    const scrollAmount = 50 // Adjust scroll amount per key press
    const zoomStep = 0.1 // Adjust zoom step per key press

    switch (e.key) {
      // Arrow keys for scrolling
      case 'ArrowLeft':
      case 'a':
      case 'h':
        containerRef.current.scrollLeft -= scrollAmount
        e.preventDefault()
        break
      case 'ArrowUp':
      case 'w':
      case 'k':
        containerRef.current.scrollTop -= scrollAmount
        e.preventDefault()
        break
      case 'ArrowRight':
      case 'd':
      case 'l':
        containerRef.current.scrollLeft += scrollAmount
        e.preventDefault()
        break
      case 'ArrowDown':
      case 's':
      case 'j':
        containerRef.current.scrollTop += scrollAmount
        e.preventDefault()
        break
      // Zoom in/out with +/-
      case 'z':
      case '+':
      case '=': // To capture both + and = keys
        setZoomLevel(prevZoom => Math.min(prevZoom + zoomStep, 3)) // Max zoom level
        e.preventDefault()
        break
      case 'x':
      case '-':
        setZoomLevel(prevZoom => Math.max(prevZoom - zoomStep, 0.5)) // Min zoom level
        e.preventDefault()
        break
      case 'Escape':
        // Close the popup
        hide()
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.setAttribute('tabindex', '0')
      containerRef.current.focus() // Set focus on render
    }
  }, [])

  // Apply zoom level to the content
  const contentStyle = {
    transform: `scale(${zoomLevel})`,
    transformOrigin: '0 0', // Ensure scaling happens from top-left corner
  }

  return (
    <div
      ref={containerRef}
      className={className}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      style={{ overflow: 'auto', cursor: 'grab' }}
      tabIndex={0} // Make the container focusable
      role="region" // Define a landmark role for accessibility
      aria-label="Pannable Image"
    >
      <div style={contentStyle}>{children}</div>
    </div>
  )
}

PannableImage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  hide: PropTypes.func,
}

export default PannableImage
